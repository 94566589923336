import { cn } from '@/lib/utils';
import Chip from 'src/components/core/atoms/Chip';
import { MarketingCardType } from 'src/components/marketing/@types';
import { Button } from 'src/components/shad-base/button';
import { getListItemKey } from 'src/utils/format';

export default function MarketingCard({
  largeTitle,
  title,
  subtitle,
  description,
  badges,
  justifyImage = 'right',
  imageUrl,
  callToAction,
  imageOnly = false,
  imageQuoteUrl,
  children,
  type = 'primary',
  miniLogoUrl
}: MarketingCardType) {
  const paddingCondition =
    !imageQuoteUrl &&
    (badges?.length > 0 ||
      title ||
      subtitle ||
      largeTitle ||
      description ||
      callToAction);

  return (
    <div
      className={cn(
        'flex h-full w-full flex-col rounded-3xl bg-card md:flex-row md:items-start',
        paddingCondition && 'p-lg',
        type === 'secondary' && 'md:bg-transparent',
        type === 'tertiary' && 'md:flex-col'
      )}
    >
      {/* Image */}
      {imageUrl && imageOnly && (
        <div className="h-full w-full rounded-3xl">
          <img
            src={imageUrl}
            className="h-full w-full rounded-3xl"
          />
        </div>
      )}
      {imageUrl && imageQuoteUrl && (
        <div className="relative h-full w-full overflow-hidden rounded-3xl bg-black bg-blend-darken">
          <img
            src={imageUrl}
            className="h-full w-full rounded-3xl opacity-40"
          />
          <div className="absolute inset-x-0 top-10 w-full px-10 py-1 leading-4">
            <p className="text-xl font-bold text-white md:text-4xl lg:text-6xl">
              {title}
            </p>
          </div>
          <img
            src={imageQuoteUrl}
            className="absolute inset-x-0 bottom-0 w-[200px] px-10 py-10 md:w-[400px]"
          />
        </div>
      )}
      {imageUrl &&
        !imageQuoteUrl &&
        !imageOnly &&
        justifyImage === 'left' && (
          <div className="mb-md h-full w-full md:mb-0 md:mr-md md:w-1/2">
            <img
              src={imageUrl}
              className="h-full w-full rounded-3xl"
            />
          </div>
        )}

      {/* Title and Badges */}
      {!imageQuoteUrl && paddingCondition ? (
        <div
          className={cn(
            'flex min-h-full w-full flex-col justify-between p-lg',
            imageUrl && 'md:w-1/2'
          )}
        >
          <div className="flex flex-col">
            {badges?.length > 0 && (
              <div className="flex flex-row md:flex-col lg:flex-row mb-md w-full">
                {badges.map((badge, idx) => {
                  return (
                    <div className="my-sm">
                      <Chip
                        key={getListItemKey(idx)}
                        label={badge.label.toUpperCase()}
                        ignoreTextTransform
                        sx={{ mr: 1 }}
                      />
                    </div>
                  );
                })}
              </div>
            )}
            {largeTitle && (
              <div>
                <h1 className="text-gray-500">{largeTitle}</h1>
              </div>
            )}
            {/* Title */}
            {title && (
              <div className="max-w-paragraph">
                <h4 className="text-xl md:text-2xl">{title}</h4>
              </div>
            )}
            {/* Subtitle */}
            {subtitle && (
              <div className="mt-sm">
                <p className="subtitle1">{subtitle}</p>
              </div>
            )}
            {/* Description */}
            {description && (
              <div className="mt-lg">
                <p className="text-start">{description}</p>
              </div>
            )}

            {/* Call to Action */}
          </div>
          <div className="flex flex-row items-center justify-between">
            {callToAction && (
              <div className="mt-lg flex flex-row justify-start">
                <Button
                  size="lg"
                  variant="link"
                  className="text-l text-wrap p-0 font-bold text-black lg:text-xl"
                  onClick={() => callToAction.onClick()}
                >
                  {callToAction.text} {` >`}
                </Button>
              </div>
            )}
            {callToAction && miniLogoUrl && (
              <div className="mt-lg flex flex-row justify-end">
                <img
                  src={miniLogoUrl}
                  className="h-[25px]  rounded-lg"
                />
              </div>
            )}
          </div>
        </div>
      ) : null}
      {imageUrl &&
        !imageQuoteUrl &&
        !imageOnly &&
        justifyImage === 'right' && (
          <div className="mt-md h-full w-full md:ml-md md:mt-0 md:w-1/2">
            <img
              src={imageUrl}
              className="h-full w-full rounded-3xl"
            />
          </div>
        )}
      {children && (
        <div className="flex h-full w-full flex-row">{children}</div>
      )}
    </div>
  );
}
